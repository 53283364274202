.information-block-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--primary-blue);
    max-width: 150px;
    height: 80px;

    .information-title {
        font-size: 20px;
        font-weight: 600;
    }

    .information-value {
        font-size: 40px;
        font-weight: 500;
    }
}