.restaurant-component-container {
    position: relative;
    width: 380px;
    height: 270px;
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;

    border-radius: 30px;

    span {
        color: white;
        font-size: 24pt;
        margin-left: 20px;
    }

    .title {
        margin-top: 30px;
    }

    .name {
        margin-top: -5px;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 30px;
        filter: brightness(50%)
    }
}