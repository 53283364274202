@import "../../themes.scss";

.layout-container {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .topBar {
        width: 100%;
        height: 70px;
        background-color: var(--primary-blue);
        margin-bottom: 60px;

    }
}