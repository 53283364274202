.frontpage-restaurant-container {
    padding-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

@media only screen and (max-width: 1200px) {
    .frontpage-restaurant-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px) {
    .frontpage-restaurant-container {
        grid-template-columns: repeat(1, 1fr);
    }
}