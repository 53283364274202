
.order-page-container {
    display: flex;
  }
  
  .order-page-info-container, .order-page-map {
    margin: 16px;
  }


  .order-page-info-container {
    flex: 1;
    flex-direction: column;
    display: flex;
    margin-right: 24px;
  }
    
  .order-page-map {
    flex: 1;
    border-radius: 30px 0 0 30px;
    overflow: clip;
    margin-right: 0;

    img {
        width: 100%;
        height: 100%;
    }
  }
  
  .order-page-info-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.14);
    border-radius: 30px;
    width: 100%;
    height: 100%;
    flex: 1;
    box-sizing: border-box;
    padding: 22px 40px;
  }
  