@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Heebo:wght@400;700&display=swap');

:root {
    --primary-blue: #009DE0;
}

.center-div {
    display: flex;
    justify-content: center;
}

body {
    margin: 0;
}

* {
    font-family: 'Heebo', sans-serif;
}


h1, h2 {
    font-family: 'Fredoka One', cursive;
    color: var(--primary-blue);
}

h3, h4 {
    font-family: 'Heebo', sans-serif;
    color: var(--primary-blue);
    font-weight: bold;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 20px;
}